import { React, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Papa from 'papaparse';
import axios from 'axios'
import URL from './config.js'


import { Button, Typography, Box } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid'

import UploadIcon from '@mui/icons-material/Upload';
import GetAppIcon from '@mui/icons-material/GetApp';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { red } from '@mui/material/colors';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import Tooltip from '@mui/material/Tooltip';
import LoadingBackdrop from './Components/LoadingBackdrop/LoadingBackdrop.js';
import CircularProgress from '@mui/material/CircularProgress';
import RedButton from './Components/RedButton/RedButton.js';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';


import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import Stack from '@mui/material/Stack';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import SpinnerButton from './Components/SpinnerButton/SpinnerButton.js';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

let JsonRowID = 0;
const Employees = () => {
    let DashboardEmail = null;
    let Jsondata = [];
    const [IP, setIP] = useState('');
    const [Loading, setLoading] = useState(true);
    const [IPLocation, setIPLocation] = useState('');
    let output_msg = '';
    let responseMsg;
    
    const [AllSignaturesTableData, SetAllSignaturesTableData] = useState([]);
    const [Filename, SetFilename] = useState({
        name: '',
        file: '',
    });
    const [sentEmailmsg, setsentEmailmsg] = useState('Please Wait. Signatures are being sent to the respective Emails');
    const [openUploadCSV, setopenUploadCSV] = useState(false);
    const [savingtoDB, setsavingtoDB] = useState(false);
    const [GenSignDialog, setGenSignDialog] = useState(false);
    const [BulkimpDialog, setBulkimpDialog] = useState(false);
    const [No_of_Signatures_updated, setNo_of_Signatures_updated] = useState(false);
    const [tempdownload, settempdownload] = useState(false);
    const [bulkimported, setbulkimported] = useState(false);

    const [emailadd, setemailadd] = useSearchParams();

    const localstoragetoken = localStorage.getItem("token");

    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIP(res.data.IPv4)
        setIPLocation(res.data.country_name)
    }

    const decrypt_email = () => {
        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                const response = await URL.post('/decryptcode',
                    {
                        u_EncryptKeyIV: emailadd.get('e'),
                        u_EncryptKeyContent: emailadd.get('c')
                    });
                DashboardEmail = response.data
                resolve(DashboardEmail)
            }, [])
        }, 2000)
    };

    useEffect(() => {
        //passing getData method to the lifecycle method
        getData()

    }, [])

    useEffect(async () => {
        FetchAllData()
    }, []);

    const FetchAllData = async () => {
        let AdminEmail;
        if (emailadd.get('e') !== null && emailadd.get('c') !== null) {
            AdminEmail = await decrypt_email();
            const response = await URL.post('/fetchallsignatures',
                {
                    u_AdminEmail: AdminEmail,
                })
            if (response) {
                SetAllSignaturesTableData(response.data.AllUsers);
                setLoading(false);
                setsavingtoDB(false);
                if (output_msg !== '') {
                    setsentEmailmsg(output_msg);
                }

                //If the template has not been downloaded, bulk import has not been done, and Generate Signature has been clicked.
                //If the template has not been downloaded, bulk import has been done, and Generate Signature has been clicked.
                //If the template has been downloaded, bulk import has been done, and Generate Signature has been clicked.
                if (
                    (!tempdownload && !bulkimported && output_msg === "Email Sent") ||
                    (!tempdownload && bulkimported && output_msg === "Email Sent") ||
                    (tempdownload && bulkimported && output_msg === "Email Sent")
                    ) {
                    setActiveStep(3)
                    
                    setGenSignDialog(true);
                    setTimeout(() => {
                        setGenSignDialog(false);
                    }, 3500);
                }
            }
        }
    }

    //Function to Send Generated Signatures to the respective users
    const GenerateSignEmails = async () => {
        setsavingtoDB(true)
        setloadingText('Please Wait. Signatures are being sent to the respective Emails');

        let ITEmail = await decrypt_email();
        const output = await URL.post('/signgenemail',
        {
                u_SignGenEmail: ITEmail,
                token: localstoragetoken,
            }
        )
        if (!tempdownload && !bulkimported && output.data === "Email Sent") {
            output_msg = output.data;
            FetchAllData()
        }
        if (!tempdownload && bulkimported && output.data === "Email Sent") {
            output_msg = output.data;
            FetchAllData()
        }
        else if (tempdownload && bulkimported && output.data === "Email Sent") {
            output_msg = output.data;
            FetchAllData()
        }
        else if (output.data === "There are no Pending Emails to be Sent!") {
            setsavingtoDB(false);
            setCrossIconBoxOpen(true);

            setTimeout(() => {
                setsentEmailmsg(output.data);
            }, 50);
            setTimeout(() => {
                setGenSignDialog(false);
                setCrossIconBoxOpen(false)
            }, 3000);
            if (!tempdownload && !bulkimported) {
                setActiveStep(3)
            }

        }
    }

    //Function to Generate Pending Signatures and save them in Database
    const GenerateSignatureFunc = async () => {
        const newarray = await GetFullArray()
        console.log(newarray);
        const result = await URL.post('/csvsigntoDB', {
            u_SignData: newarray
        });
        if (result.data.msg === "Saved in Database") {
            responseMsg = result.data.msg;
            setbulkimported(true); //set the bulkimported variable to true
            setBulkimpDialog(true); //open the Signature Email Sent Dialog
            setNo_of_Signatures_updated(result.data.Numofuniquesign) //set the numbers of records updated in the database
            setsavingtoDB(false); //close the loading dialog box
            setActiveStep(2) //set the Import Your Bulk Data step to true
            FetchAllData()
        }
        setTimeout(() => {
            setBulkimpDialog(false); //close the Signature Email Sent Dialog
        }, 3000)
    }

    const SignatureTableColumns = [
        { field: 'FullName', headerName: 'Full Name', width: 200 },
        { field: 'PositionTitle', headerName: 'Position Title', width: 200 },
        { field: 'Email', headerName: 'Email', width: 300 },
        { field: 'Extension', headerName: 'Extension', width: 130 },
        { field: 'LineNumber', headerName: 'Phone Number', width: 200 },
        { field: 'MobileNumber', headerName: 'Mobile Number', width: 200 },
        {
            field: 'IsSignatureGenerated', headerName: 'Is Signature Generated', width: 200,
            renderCell: (params) => {
                if (params.value === "0") {
                    params.value = <CloseIcon sx={{ color: red[500] }} />
                }
                else {
                    params.value = <DoneIcon color="success" />
                }
                return params.value;
            }
        },
        { field: 'CreatedOn', headerName: 'Created On', width: 120 },

    ]

    const CustomToolbar = () => {
        return (
            <div className='Employee-Main'>
                <LoadingBackdrop open={Loading} icon={<CircularProgress sx={{ color: '#fff' }} />} />
            </div>
        );
    }

    const handleFileSelect = () => {
        setsavingtoDB(true)        
        setloadingText('Please wait while your Signatures are being saved...');
        
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                Papa.parse(Filename.file, {
                    header: true,
                    skipEmptyLines: true,
                    complete: (results) => {
                        Jsondata = results.data;
                        resolve(Jsondata)
                        console.log("results.data ", results.data);
                    }
                });
            }, 2000)
        });
    }
    const UploadCSVFunc = async () => {
        setopenUploadCSV(false)
        const UserData = await handleFileSelect();
        SetFilename({
            name: '',
            file: ''
        })
        if (UserData !== []) {
            GenerateSignatureFunc();
        }
    }

    const JsonRowIDfunc = () => {
        return (JsonRowID++)
    };

    const NewJsonData = Jsondata.map((o) => ({
        id: JsonRowIDfunc(),
        ...o,
    }));

    // Dialog Code    
    const handleClickOpen = () => {
        setopenUploadCSV(true);
        SetFilename({
            name: '',
            file: ''
        })
    };

    const handleClose = () => {
        setopenUploadCSV(false);
        SetFilename({
            name: '',
            file: ''
        })
    };

    const SetFilenameFunc = (event) => {
        SetFilename({
            name: event.target.files[0].name,
            file: event.target.files[0],
        });
    };
    // Dialog Code

    // Signatures To Database Code
    const chcklang = (array) => {

        // Arabic characters fall in the Unicode range 0600 - 06FF (\u0600-\u06FF)        
        let regEx = new RegExp(/^[\u00C0-\u024F\u1E00-\u1EFF\u0600-\u06FF\s\p{P}]/);

        if (regEx.test(array["Full Name"]) && regEx.test(array["Position Title"])) {
            return "AR";
        }
        else {
            return "EN";
        }
    }

    const GetFullArray = async () => {
        console.log(Jsondata)
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const newArr = Jsondata.map((o) => {
                    return {
                        ...o,
                        lang: chcklang(o),
                        IsSignatureGenerated: false,
                        IPAddress: IP,
                        GeoLocation: IPLocation
                    }
                });
                resolve(newArr)
            }, 2000)
        })
    }

    

    // Signatures To Database Code
    const steps = ['Download Template', 'Import Your Bulk Data', 'Generate Signatures'];

    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundImage:
                'linear-gradient(135deg, rgba(237,28,36,1) 16%, rgba(255,153,156,1) 100%)',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundImage:
                'linear-gradient(135deg, rgba(255,153,156,1) 16%, rgba(237,28,36,1) 100%)',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
    }));

    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;

        const icons = {
            1: <DownloadForOfflineIcon />,
            2: <GroupAddIcon />,
            3: <VideoLabelIcon />,
        };

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {completed ? <CheckCircleIcon /> : icons[String(props.icon)]
                }
            </ColorlibStepIconRoot>
        );
    }

    ColorlibStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
        /**
         * The label displayed in the step icon.
         */
        icon: PropTypes.node,
    };
    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient(135deg, rgba(255,153,156,1) 16%, rgba(237,28,36,1) 100%)',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient(135deg, rgba(255,153,156,1) 16%, rgba(237,28,36,1) 100%)',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor:
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderRadius: 1,
        },
    }));
const [loadingText, setloadingText] = useState('');
const [CrossIconBoxOpen, setCrossIconBoxOpen] = useState();
    return (
        <div>
            <Dialog open={openUploadCSV}>
                <DialogTitle>Upload Employee CSV</DialogTitle>
                <DialogContent>
                    <form>
                        <TextField
                            disabled
                            value={Filename.name === '' ? 'File Name' : Filename.name}
                            readOnly={true}
                            fullWidth
                            margin='dense'
                        />
                        <Button variant="contained" component="label">
                            Choose File
                            <input hidden type="file" onChange={SetFilenameFunc} />
                        </Button>
                    </form>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <SpinnerButton text={'Upload'} fg={UploadCSVFunc}></SpinnerButton>
                </DialogActions>
            </Dialog>

            {/* Dialog Box for Loading Icon */}
            <Dialog className='DialogBox' open={savingtoDB}>
                <DialogContent sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Player
                            autoplay
                            loop
                            src='https://assets9.lottiefiles.com/packages/lf20_k0dvn0vb.json' //Loading Icon
                            style={{ height: '100px', width: '100px' }}
                        >
                            <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                        </Player>
                        <Typography variant='p' >{loadingText}</Typography>
                    </Box>
                </DialogContent>
            </Dialog>

            {/* Dialog Box for Tick Icon with No of Emails sent */}
            <Dialog className='DialogBox' open={BulkimpDialog}>
                <DialogContent sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Player
                            autoplay
                            keepLastFrame={true}
                            src='https://assets3.lottiefiles.com/packages/lf20_yom6uvgj.json' //Tick icon
                            style={{ height: '100px', width: '100px' }}
                        >
                            <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                        </Player>
                        <Typography variant='p' >{No_of_Signatures_updated} Signatures has been uploaded</Typography>
                    </Box>
                </DialogContent>
            </Dialog>

            {/* Dialog Box for Tick Icon with Emails sent*/}
            <Dialog className='DialogBox' open={GenSignDialog}>
                <DialogContent>
                    <div className='loadtick'>
                        <Player
                            autoplay
                            keepLastFrame={true}
                            src='https://assets3.lottiefiles.com/packages/lf20_yom6uvgj.json'
                            style={{ height: '100px', width: '100px' }}
                        >
                            <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                        </Player>
                    </div>
                    <Box sx={{ width: '100%' }}>
                        <Typography sx={{ textAlign: 'center' }} variant='body2' gutterBottom>{sentEmailmsg}</Typography>
                    </Box>
                </DialogContent>
            </Dialog>

            {/* Dialog Box for Cross Icon */}
            <Dialog className='DialogBox' open={CrossIconBoxOpen}>
                <DialogContent>
                    <div className='loadtick'>
                        <Player
                            autoplay
                            keepLastFrame={true}
                            src='https://assets9.lottiefiles.com/packages/lf20_j45vpbyx.json'
                            style={{ height: '100px', width: '100px' }}
                        >
                            <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                        </Player>
                    </div>
                    <Box sx={{ width: '100%' }}>
                        <Typography sx={{ textAlign: 'center' }} variant='body2' gutterBottom>{sentEmailmsg}</Typography>
                    </Box>
                </DialogContent>
            </Dialog>

            <Box sx={{ width: '50%', margin: '20px auto' }}>
                <Stack sx={{ width: '100%' }} spacing={4}>
                    <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel className="steps" StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Stack>
            </Box>
            <Box sx={{ margin: '0px 0px 20px 0px' }}>
                <Typography variant='p' >
                    You can Generate multiple Email Signatures by uploading the CSV.
                </Typography>
            </Box>
            <DataGrid
                autoHeight={true}
                checkboxSelection={false}
                rowHeight={40}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[10]}
                slots={{
                    toolbar: CustomToolbar,
                }}
                columns={SignatureTableColumns}
                rows={AllSignaturesTableData}
            />

            <Box sx={{ width: '100%', textAlign: 'center', margin: '20px 0px 20px 0px' }}>
                <div>
                    <Tooltip title="Download Template to Add Data">
                        <RedButton onclickfunc={
                            () => {
                                handleNext()
                                settempdownload(true)
                            }
                        } text="Download Template" hreflink={window.location.origin + '/GenieSign-Email-Signature-Template.csv'} Icon={<GetAppIcon />} />
                    </Tooltip>
                    <RedButton text="Import Your Bulk Data" onclickfunc={() => {
                        handleClickOpen()
                    }} Icon={<UploadIcon />} />
                    <RedButton text="Generate Signatures" onclickfunc={() => {
                        GenerateSignEmails();
                        if (tempdownload && bulkimported) {
                            handleNext();
                        }
                    }} Icon={<SettingsApplicationsIcon />} />

                </div>
            </Box>

        </div>
    );
};
export default Employees;