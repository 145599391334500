import { React, useState, useEffect, useRef } from 'react';
import { useSearchParams, NavLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import { DataGrid } from '@mui/x-data-grid'
import LogoutIcon from '@mui/icons-material/Logout';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { red, white } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingBackdrop from './Components/LoadingBackdrop/LoadingBackdrop.js';
import URL from './config.js'

const useComponentVisible = (initialIsVisible) => {
    const [isComponentVisible, setIsComponentVisible] = useState(
        initialIsVisible
    );
    const ref = useRef(null);

    const handleHideDropdown = (event) => {
        if (event.key === "Escape") {
            setIsComponentVisible(false);
        }
    };

    const handleClickOutside = (event) => {

        setIsComponentVisible(false);
        console.log(isComponentVisible);

    };

    useEffect(() => {
        document.addEventListener("keydown", handleHideDropdown, true);
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("keydown", handleHideDropdown, true);
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
}

const DashboardMain = () => {
    const {
        ref,
        isComponentVisible,
        setIsComponentVisible
    } = useComponentVisible(false);

    let DashboardEmail = null;

    const [AdminName, SetAdminName] = useState();
    const [PendingSignatures, SetPendingSignatures] = useState();
    const [TotalSignatures, SetTotalSignatures] = useState();
    const [TotalUsers, SetTotalUsers] = useState();
    const [FirstUnpaidSignatureDate, SetFirstUnpaidSignatureDate] = useState();
    const [SignaturesGeneratedYesterday, SetSignaturesGeneratedYesterday] = useState();
    const [NewUsersTodayCount, SetNewUsersTodayCount] = useState();
    const [Loading, SetLoading] = useState(true);
    const [isProfileMenuOpen, SetisProfileMenuOpen] = useState(false);
    const [AllSignaturesTableData, SetAllSignaturesTableData] = useState([]);
    const [emailadd, setemailadd] = useSearchParams();

    const SignatureTableColumns = [
        { field: 'FullName', headerName: 'Full Name', width: 200 },
        { field: 'PositionTitle', headerName: 'Position Title', width: 200 },
        { field: 'Email', headerName: 'Email', width: 300 },
        { field: 'Extension', headerName: 'Extension', width: 130 },
        { field: 'LineNumber', headerName: 'Phone Number', width: 200 },
        { field: 'MobileNumber', headerName: 'Mobile Number', width: 200 },
        {
            field: 'IsSignatureGenerated', headerName: 'Is Signature Generated', width: 200,
            renderCell: (params) => {
                if (params.value === "0") {
                    params.value = <CloseIcon sx={{ color: red[500] }} />
                }
                else {
                    params.value = <DoneIcon color="success" />
                }
                return params.value;
            }
        },
        { field: 'CreatedOn', headerName: 'Created On', width: 120 },
    ]

    const decrypt_email = () => {
        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                const response = await URL.post('/decryptcode',
                    {
                        u_EncryptKeyIV: emailadd.get('e'),
                        u_EncryptKeyContent: emailadd.get('c')
                    });
                DashboardEmail = response.data
                resolve(DashboardEmail)
            }, [])
        }, 2000)
    };

    const NavItem = (props) => {
        return (
            <li className="profile-submenu-item">
                <NavLink to={props.to} className="profile-submenu-icon-button" onClick={
                    (event) => {
                        event.stopPropagation();
                        localStorage.clear("token");

                    }}>
                    <div className="icon-button">{props.icon}</div>{props.title}
                </NavLink>
            </li>
        );
    };

    const profile_menu = (event) => {
        return (
            <ul className="profile-submenu">
                <NavItem icon={<LogoutIcon />} title="Logout" to="/login" />
            </ul>
        );
    };

    let AdminEmail;
    useEffect(async () => {
        if (emailadd.get('e') !== null && emailadd.get('c') !== null) {
            AdminEmail = await decrypt_email();
            const response = await URL.post('/fetchallsignatures',
                {
                    u_AdminEmail: AdminEmail,
                })
            if (response) {
                SetLoading(false);
                SetPendingSignatures(response.data.PendingSignatures);
                SetTotalSignatures(response.data.SignTotal);
                SetAdminName(response.data.AdminName);
                SetAllSignaturesTableData(response.data.AllSignatures);
                SetTotalUsers(response.data.TotalUsers);
                SetFirstUnpaidSignatureDate(response.data.FirstUnpaidSignatureDate);
                SetSignaturesGeneratedYesterday(response.data.SignaturesGeneratedYesterday);
                SetNewUsersTodayCount(response.data.NewUsersTodayCount);
            }
        }
    }, []);

    return (
        <div className="dashboard-main">                        
            <LoadingBackdrop open={Loading} icon={<CircularProgress sx={{ color: '#fff' }} /> } />
            <div className="Right-Main-Items">
                <Card className="quickinfobox">
                    <img src="images/Pending-Invoice-512.png" className="quickinfoimg" />
                    <CardContent>
                        <Typography className="boxheading" >
                            Pending Signatures
                        </Typography>

                        <Typography className="boxcount">
                            {PendingSignatures}
                        </Typography>

                        <Divider variant="middle" style={{ backgroundImage: 'linear-gradient(to right, rgba(52, 71, 103, 0), rgb(65, 64, 66), rgba(52, 71, 103, 0))', opacity: 0.25, marginTop: '10px' }} />

                        <Typography className="boxstatus">
                            {FirstUnpaidSignatureDate === 0 ? 'No Pending Signatures' : FirstUnpaidSignatureDate === null ? 'No Pending Signatures' : `Pending Signatures Since ${FirstUnpaidSignatureDate}`}
                        </Typography>
                    </CardContent>
                </Card>
                <Card className="quickinfobox">
                    <img src="images/Stationery-Pen-512.png" className="quickinfoimg" />
                    <CardContent>
                        <Typography className="boxheading" >
                            Total Signatures
                        </Typography>

                        <Typography className="boxcount">
                            {TotalSignatures}
                        </Typography>

                        <Divider variant="middle" style={{ backgroundImage: 'linear-gradient(to right, rgba(52, 71, 103, 0), rgb(65, 64, 66), rgba(52, 71, 103, 0))', opacity: 0.25, marginTop: '10px' }} />

                        <Typography className="boxstatus">
                            {SignaturesGeneratedYesterday} Signatures Generated Today
                        </Typography>
                    </CardContent>
                </Card>

                <Card className="quickinfobox">
                    <img src="images/User-Group-512.png" className="quickinfoimg" />
                    <CardContent>
                        <Typography className="boxheading" >
                            Total Users
                        </Typography>

                        <Typography className="boxcount">
                            {TotalUsers}
                        </Typography>

                        <Divider variant="middle" style={{ backgroundImage: 'linear-gradient(to right, rgba(52, 71, 103, 0), rgb(65, 64, 66), rgba(52, 71, 103, 0))', opacity: 0.25, marginTop: '10px' }} />

                        <Typography className="boxstatus">
                            {NewUsersTodayCount} New Users Today
                        </Typography>
                    </CardContent>
                </Card>
            </div>

            <Box mt={3} sx={{ width: '100%' }}>
                <Typography mb={1} variant="h6" component="p" >
                    All Signatures
                </Typography>
                <DataGrid
                    className='pd-sign-tbl'
                    rows={AllSignaturesTableData}
                    columns={SignatureTableColumns}
                    autoHeight={true}
                    checkboxSelection={false}
                    rowHeight={40}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10]}
                />
            </Box>
        </div>
    );
};
export default DashboardMain;